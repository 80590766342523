<template>
  <div class="form_class">
    <div class="upbBtns">
      <fileUpload
          ref="uploadComponents"
          @selectFile="uploadFile"
          @uploadCompleteOSS="uploadCompleteOSS"
      >
        <el-button type="primary" class="upBtn" size="small">本地上传</el-button>
      </fileUpload>
      <!-- <el-button type="primary" class="upBtn" @click="handleUp" size="small">资料库选择</el-button> -->
    </div>
    <el-form
        label-width="100px"
        ref="form"
        :model="form"
        :rules="rules"
        label-position="left"
    >
      <el-form-item label="文件名" prop="name">
        <el-input placeholder="请输入文件名" v-model="form.name" class="input_class" >
        </el-input>
      </el-form-item>
      <el-form-item label="文件类型" prop="file_type">
        <el-select v-model="form.file_type" placeholder="请选择文件类型" class="input_class">
          <el-option
              v-for="item in  file_type_list"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="文件格式" prop="format">
        {{ form.format ?  form.format : '待上传' }}
      </el-form-item>
      <el-form-item label="文件大小" prop="size">
        {{ form.size ?  (form.size/1024).toFixed(2) + 'KB' : '待上传' }}
      </el-form-item>
      <el-form-item label="备注" prop="remarks">
        <el-input
            class="input_class"
            type="textarea"
            placeholder="请输入备注"
            rows="4"
            v-model="form.remarks"
            maxlength="256"
            
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="btns">
      <el-button size="small" @click="handleClose">取消</el-button>
      <el-button type="primary" class="submitBtn" size="small" @click="handleSubmit" :loading="loading"> 确定</el-button>
    </div>
    <el-dialog
        title="资料库"
        :visible.sync="dataBankDialog"
        width="800px"
        append-to-body
    >
      <selectDataBank
          isRemarks
          v-if="dataBankDialog"
          @uploadDataBank="uploadDataBank"
          @closeUploadDataBank="closeUploadDataBank"
      ></selectDataBank>
    </el-dialog>
  </div>
</template>

<script>
import selectDataBank from "~bac/components/dealManagement/myEntrust/uploadEntrustSon/selectDataBank";

import fileUpload from "@/baseComponents/fileUpload";
import {getFileType} from "@/baseUtils";
export default {
  name: "uploadingFileDialog",
  components: { fileUpload, selectDataBank },
  data() {
    return {
      form: {
        name: '',
        format: '',
        size: '',
        url: '',
        remarks: '',
        file_type: '',
      },
      rules: {
        name: [{ required: true, message: " ", trigger: "change" }],
        file_type: [{ required: true, message: " ", trigger: "change" }],
      },
      file_type_list: [
        {
          label: '海运委托书',
          value:  1
        },
        {
          label: 'MSDS',
          value:  2
        },
        {
          label: '危包证',
          value:  3
        },
        {
          label: '其他',
          value:  5
        }
      ],
       file_type_list_fcl: [
        {
          label: '海运委托书',
          value:  1
        },
        {
          label: 'MSDS',
          value:  2
        },
        {
          label: '危包证',
          value:  3
        },
        {
          label: '商检报告',
          value:  4
        },
         {
          label: '发货人电子章',
          value:  6
        },
         {
          label: '非危鉴定',
          value:  7
        },
        // {
        //   label: '其他',
        //   value:  5
        // }
      ],
      loading: false,
      dataBankDialog: false
    }
  },
  methods: {
    uploadFile(val) {
      let obj = {
        name: val[0].name,
        size: val[0].size,
        format: getFileType(val[0].name),
      }
      this.form = Object.assign({}, obj)
      this.$refs.uploadComponents.uploadFileToOss()
    },
    uploadCompleteOSS(val) {
      this.form.url = val.url
    },
    handleClose() {
      this.$emit('handleClose')
    },
    async handleSubmit() {
      if (this.form.url =='') {
        return this.$message.warning('请上传文件')
      }
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          let parmas = {
            order_id: this.$route.query.id,
            user_id: this.USER_INFO.id
          }
          let files_info = {
            file_type: this.form.file_type,
            name: this.form.name,
            format: this.form.format,
            size: (this.form.size/1024).toFixed(2),
            url: this.form.url,
            remarks: this.form.remarks
          }
          parmas = Object.assign(parmas,files_info)
          this.loading = true
          let res = await this.$store.dispatch("baseConsole/seaOrderFileAdd", parmas);
          if (res.success) {
            this.loading = false
            this.$message.success(this.$t('Successful_operation'))
            this.handleClose()
          }
        }
      });
    },
    handleUp() {
      this.dataBankDialog = true
    },
    // 在资料库选择后
    uploadDataBank(row, type) {
      row.size = (row.size * 1024).toFixed(2)
      this.form = Object.assign({}, row)
      this.dataBankDialog = false
    },
    //  关闭资料库
    closeUploadDataBank() {
      this.dataBankDialog = false
    },
  }
}
</script>

<style scoped lang="less">
.form_class {
  padding: 0 20px 20px;
  .upbBtns {
    display: flex;
  }
}
.input_class {
  width: 500px;
}
.upBtn {
  margin-bottom: 20px;
  margin-right: 20px;
}
.btns {
  margin-top: 10px;
  text-align: right;
}
</style>