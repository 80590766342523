<template>
	<div>
		<el-dialog title="配置跟踪节点" :visible.sync="showDialog" width="850px" :close-on-click-modal="false">
			<p style="margin-bottom: 20px;">请选择需要展示的跟踪节点，部分节点可能因为订单提供的服务不同，勾选后无法展示，可咨询订单操作或客服</p>
			<div v-loading="dialogLoading" v-if="showDialog">
				<el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
				<div style="margin: 15px 0;"></div>
				<el-checkbox-group v-model="hasChecked" @change="change">
					<el-checkbox class="checkItem" :label="item.value" v-for="(item, index) in dataList" :key="index">{{
						item.label }}</el-checkbox>
				</el-checkbox-group>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="showDialog = false" size="small">取消</el-button>
				<el-button type="primary" @click="saveContent" :loading="dialogBtnLoading" size="small">保存</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
export default {
	props: {
		tableType: {
			type: Number,
			required: true,
			default: 7, // 使用范围：1-FBA订单列表，2-对账列表，3-账单列表，4-对账单列表，5-财务审核列表，6-海运订单列表，7-订单详情
		}
	},
	data() {
		return {
			showDialog: false,
			dialogLoading: false,
			dialogBtnLoading: false,
			hasChecked: [],
			dataList: [],
			checkAll: false,
			isIndeterminate: true
		}
	},
	mounted() {
		
	},
	methods: {
		change(value) {
			let checkedCount = value.length;
      this.checkAll = checkedCount === this.dataList.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.dataList.length;
		},
		openDialog() {
			this.dataList = [];
			this.getSopList();
			this.getUserSet();
			this.showDialog = true
		},
		handleCheckAllChange(val){
			this.hasChecked = val ? this.dataList.map((res)=> Number(res.value)) : [];
			this.isIndeterminate = false;
		},
		async getSopList(){
			let res = await this.$store.dispatch('baseConsole/getFbaNodes',{
				module_type: 2, //模块类型：1-FBA，2-海运出口
				is_public: 1, // 是否公开：0-否，1-是
			})
			if(res.success){
				if(res.data){
					res.data.forEach(item => {
						item.label = item.name_zh;
						item.value = item.id;
					});
				}
				this.dataList = res.data;
			}
		},
		getUserSet() {
			this.$store.dispatch('baseConsole/getUserSet',{
				used_for: this.tableType,
				type: 3,
				created_from: 2, // 创建来源：1-后台，2-前台
				user_id: this.USER_ID,
			}).then((res) => {
				if (res.data && res.data.data && res.data.data.length) {
					let hasChecked = res.data.data.split(",").map((res) => Number(res));
					this.hasChecked = hasChecked.sort((a, b) => a - b);
				} else {
					this.hasChecked = []
					switch (this.tableType) {
						case 7:
							this.hasChecked = this.dataList.map((res)=> Number(res.value))
							break;
						default:
							this.hasChecked = []
							break;
					}
				}
				this.$emit('getCheckColum', { list: this.hasChecked })
			});
		},
		checkAllSelect(){
			let newArr = [];
			this.dataList.forEach((item)=>{
				if(item.label == '订舱' || item.label == '预配' || item.label == '开船'){
					newArr.push(item);
				}
			})
			let checkBoolen = false;
			for(let i=0 ;i<newArr.length;i++){
				if(this.hasChecked.indexOf(newArr[i].value) < 0){
					checkBoolen = true;
					break;
				}
			}
			if(checkBoolen){
				let message = ``;
				message = newArr.map((res)=> res.label).join('，')
				this.$message.warning(`${message}为必选，不可取消，请检查勾选项`)
			};
			return checkBoolen;
		},
		saveContent() {
			if(this.checkAllSelect()){
				return false;
			}
			this.dialogBtnLoading = true;
			this.hasChecked = this.hasChecked.sort((a, b) => a - b);
			this.$store.dispatch('baseConsole/updateUserSet',{
				user_id: this.USER_ID,
				created_from: 2,
				used_for: this.tableType,
				type: 3,
				data: this.hasChecked.join(","),
			}).then((res) => {
				if(res.success){
					this.$message.success('操作成功')
					this.$emit('getCheckColum', { list: this.hasChecked })
					this.dialogBtnLoading = false;
					this.showDialog = false;
				}else{
					this.dialogBtnLoading = false;
					this.$message.error('操作失败')
				}
			});
		},
	},
}
</script>
<style scoped lang="less">
.checkItem {
	margin-bottom: 10px;
	width: 120px;
}
</style>