import { render, staticRenderFns } from "./dialogSetColum.vue?vue&type=template&id=67f55b8a&scoped=true"
import script from "./dialogSetColum.vue?vue&type=script&lang=js"
export * from "./dialogSetColum.vue?vue&type=script&lang=js"
import style0 from "./dialogSetColum.vue?vue&type=style&index=0&id=67f55b8a&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../../../../../../opt/deploy_node_modules/ailaworld/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67f55b8a",
  null
  
)

export default component.exports