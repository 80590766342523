<template>
  <div v-loading="loading" class="itemBox">
    <div class="tableContainer" v-for="(detail, o) in container_list" :key="detail.id">
      <div class="boxtitle" v-show="detail.identifier">作业编号: {{ detail.identifier }}</div>
      <div class="box1Content">
        <div class="box1Item">
          <div class="box1ItemFl">送货车辆</div>
          <div class="box1ItemFr">{{ detail.delivery_vehicle | textFormat }}</div>
        </div>
        <div class="box1Item">
          <div class="box1ItemFl">登记时间</div>
          <div class="box1ItemFr">
            {{ detail.registration_time | secondFormat("LLLL") }}
          </div>
        </div>
        <div class="box1Item">
          <div class="box1ItemFl">卸货开始</div>
          <div class="box1ItemFr">
            {{ detail.unloading_start_time | secondFormat("LLLL") }}
          </div>
        </div>
        <div class="box1Item">
          <div class="box1ItemFl">卸货结束</div>
          <div class="box1ItemFr">
            {{ detail.unloading_end_time | secondFormat("LLLL") }}
          </div>
        </div>
        <div class="box1Item">
          <div class="box1ItemFl">货物备注</div>
          <div class="box1ItemFr">{{ detail.cargo_remarks | textFormat }}</div>
        </div>
      </div>
      <div class="content">
        <div class="total" style="margin-top: 10px">
          <span>入库总件数：{{ detail.quantity | textFormat }}</span>
          <span>入库总重量：{{ detail.weight | textFormat }}</span>
          <span>入库总体积：{{ detail.volume | textFormat }}</span>
        </div>
        <el-table :data="detail.cargo_data ? detail.cargo_data : []" style="width: 100%" border>
          <el-table-column prop="length" label="长"> </el-table-column>
          <el-table-column prop="width" label="宽"> </el-table-column>
          <el-table-column prop="height" label="高"> </el-table-column>
          <el-table-column prop="packing_type" show-overflow-tooltip label="包装"> </el-table-column>
          <el-table-column prop="actual_quantity" show-overflow-tooltip label="件数"> </el-table-column>
          <el-table-column prop="actual_volume" label="体积"> </el-table-column>
          <el-table-column prop="cargo_name" show-overflow-tooltip label="货物信息"> </el-table-column>
        </el-table>
      </div>
      <div class="content" v-if="detail.picture_info && detail.picture_info.length">
        <div class="img_scll">
          <div class="jt_l" :class="{
            jiantou_disabled: detail.img_index == 0 || detail.picture_info.length == 0,
            jiantou: detail.img_index != 0 && detail.picture_info.length != 0,
          }" @click="handle_jt(o, 'jt_l')">
            <i class="el-icon-back"></i>
          </div>
          <div class="img_list" v-if="detail.picture_info.length">
            <div v-for="(item, i) in detail.picture_info.slice(
              detail.img_index * 5,
              detail.img_index * 5 + 5
            )" :key="i">
              <el-image style="width: 100px; height: 100px;" :preview-src-list="detail.picture_info.slice(
                detail.img_index * 5,
                detail.img_index * 5 + 5
              )
                " :src="item" fit="corver" />
            </div>
          </div>
          <div v-else class="nopic">暂无图片</div>
          <div class="js_r" :class="{
            jiantou_disabled: detail.img_index == detail.img_index_total - 1 || detail.picture_info.length == 0,
            jiantou: detail.img_index < detail.img_index_total - 1,
          }" @click="handle_jt(o, 'jt_r')">
            <i class="el-icon-right"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props:{
    info: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data() {
    return {
      loading: false,
      container_list: [
        {
          warehousing_picture: [],
          cargo_data: []
        }
      ],
      activeName: 0,
    };
  },
  mounted() {
  },
  methods: {
    handle_jt(index, type) { // 图片查看
      if (type == 'jt_l' && this.container_list[index].img_index != 0) {
        this.container_list[index].img_index--
      }
      if (type == 'jt_r' && this.container_list[index].img_index < this.container_list[index].img_index_total - 1) {
        this.container_list[index].img_index++
      }
      this.container_list = JSON.parse(JSON.stringify(this.container_list))
    },
    async getDetail() {
      let params = {
        warehouse_no: this.info.warehouse_no
      };
      this.loading = true;
      let res = await this.$store.dispatch('baseConsole/enterWarehouseDetailsList',params);
      this.loading = false;
      if (res.success) {
        this.container_list = res.data || [];

        this.container_list = this.container_list.map(item=>{
          item.img_index = 0
          item.img_index_total = Math.ceil(item.warehousing_picture.length/5)
          item.picture_info = item.warehousing_picture;
          return item
        })
      }
    },
  },
};
</script>

<style scoped lang="less">
.submitBtn {
  margin-top: 10px;
  text-align: right;
}

.refusedTitle {
  margin: 10px 0;
}

.chaHao {
  margin-left: 10px;
  cursor: pointer;
}

.total {
  margin: 20px 0;

  span {
    padding-right: 100px;
  }
}

.box1Content {
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
 

  .box1Item {
    margin: 10px 0;
    width: 46%;
    display: flex;

    .box1ItemFl {
      width: 25%;
      text-align: left;
    }

    .box1ItemFr {
      width: 75%;
      padding-right: 20px;

      p {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-bottom: 10px;
        cursor: pointer;
      }
    }
  }
}

.tableContainer {
  width: 100%;
  background: #ffffff;
  border: 1px solid #ccc;
  font-size: 14px;
  margin-bottom: 20px;
  color: #333;
  position: relative;
  .boxtitle{
    position: absolute;
    top: -10px;
    padding: 0 10px;
    left: calc(50% - 120px);
    width: auto;
    background-color: white;
  }
  .content {
    padding: 15px 20px;
  }
}

.titleBox {
  position: relative;
  width: 100%;
  height: 54px;
  line-height: 54px;
  border-bottom: 1px #c0c4cc solid;
  padding-left: 32px;

  .titleLine {
    width: 2px;
    height: 16px;
    background: #22d00e;
    position: absolute;
    left: 0;
    top: 19px;
  }
}

.img_scll {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .jiantou {
    font-size: 25px;
    text-align: center;
    line-height: 50px;
    font-weight: bold;
    width: 50px;
    height: 50px;
  }

  .jiantou :hover {
    font-size: 25px;
    text-align: center;
    line-height: 50px;
    font-weight: bold;
    width: 50px;
    height: 50px;
    background-color: rgba(31, 45, 61, 0.11);
    border-radius: 50%;
    cursor: pointer;
  }

  .jiantou_disabled {
    font-size: 25px;
    text-align: center;
    line-height: 50px;
    font-weight: bold;
    cursor: not-allowed;
  }

  .img_list {
    display: flex;
    justify-content: space-evenly;
    width: 80%;

    div {
      // cursor: pointer;
    }
  }
}
.itemBox{
  width: 100%;
  
  margin-right: 10px;
  margin-bottom: 10px;
}

</style>
