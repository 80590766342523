<template>
  <div>
    <el-table
        :data="dataList"
        border
        highlight-current-row
        @current-change="handleCurrentChange"
        size="mini"
        style="width: 100%;height: 500px;overflow: auto"
        :loading="loading"
    >
      <el-table-column prop="name" label="文件名" width="180" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          {{ scope.row.name | textFormat }}
        </template>
      </el-table-column>
      <el-table-column prop="format" label="文件类型" width="180" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          {{ scope.row.format | textFormat }}
        </template>
      </el-table-column>
      <el-table-column prop="size" label="文件大小" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          {{ scope.row.size | textFormat }}KB
        </template>
      </el-table-column>
      <el-table-column prop="address" label="文件类别" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          {{ scope.row.file_type | file_type }}
        </template>
      </el-table-column>
      <el-table-column prop="remarks" label="备注" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          {{ scope.row.remarks | textFormat }}
        </template>
      </el-table-column>
    </el-table>
    <div class="btns">
      <el-button size="small" @click="handleClose">取消</el-button>
      <el-button type="success" class="submitBtn" size="small" @click="handleSubmit">确定</el-button>
    </div>
  </div>

</template>

<script>
export default {
  name: "selectDataBank",
  props: {
    dataBankType: {
      type: Number | String,
      default: ''
    },
    // 返回时，是否有备注，目前只有在委托详情的上传文件中有
    isRemarks: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dataList: [],
      nopage: 1,
      currentRow: null,
      loading: false
    }
  },
  filters: {
    file_type(val) {
      let str = "-";
      if (val == 1) {
        str = "海运委托书";
      }
      if (val == 2) {
        str = "MSDS";
      }
      if (val == 3) {
        str = "危包证";
      }
      if (val == 4) {
        str = "商检报告";
      }
      if (val == 5) {
        str = "其他";
      }
       if (val == 6) {
        str = "发货人电子章";
      }
       if (val == 7) {
        str = "非危鉴定";
      }
      return str;
    }
  },
  mounted() {
    this.getDataList()
  },
  methods: {
    async getDataList() {
      let parmas = {
        user_id: this.USER_INFO.id,
        file_type: this.dataBankType
      }
      this.loading = true
      let res = await this.$store.dispatch("API_bookingSpace/getBookingAttachmentList", parmas);
      this.loading = false
      if (res.success) {
        this.dataList = res.data
      }
    },
    handleCurrentChange(row) {
      this.currentRow = Object.assign({}, row)
    },
    handleSubmit() {
      if (this.currentRow !== null) {
        let row = {
          file_type: this.currentRow.file_type,
          format: this.currentRow.format,
          attachment_id: this.currentRow.id,
          name: this.currentRow.name,
          url: this.currentRow.url,
          size: this.currentRow.size,
        }
        if (this.isRemarks) {
          row.remarks = this.currentRow.remarks
        }
        this.$emit('uploadDataBank', row, this.dataBankType)
      } else {
        this.$message.warning('请选择需要上传的文件')
      }
    },
    handleClose() {
      this.$emit('closeUploadDataBank')
    }
  }
}
</script>

<style scoped lang="less">
/deep/ .el-table__body tr.current-row > td {
  background-color: rgb(145, 190, 66) !important;
  div {
    color: #fff;
  }
}
.btns {
  margin-top: 20px;
  text-align: right;
}
.submitBtn {
  background-color: #91BE42;
  border-color: #91BE42;
}
/deep/ .el-table::before {
  display: none;
}
</style>