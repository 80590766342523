<template>
  <div class="itemBox">
    <div class="boxTitle">{{ from ? "收发通信息" : "客户发运信息" }}</div>
    <el-form :show-message="false" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="10px" :disabled="true"
      class="demo-ruleForm">
      <div class="form_div_box">
        <!-- 发货人 -->
        <div class="form_item">
          <div class="lable_button">
            <span>发货人</span>
          </div>
          <el-form-item>
            <el-input v-model="ruleForm.shipper.user_name" placeholder="发货人姓名">
              <i class="el-icon-user el-input__icon" slot="prefix"></i>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model="ruleForm.shipper.address" placeholder="发货人地址">
              <i class="el-icon-location el-input__icon" slot="prefix"></i>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model="ruleForm.shipper.telephone" placeholder="联系电话">
              <i class="el-icon-phone el-input__icon" slot="prefix"></i>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model="ruleForm.shipper.fax" placeholder="传真">
              <i class="el-icon-chat-dot-square el-input__icon" slot="prefix"></i>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model="ruleForm.shipper.email" placeholder="邮箱">
              <i class="el-icon-message el-input__icon" slot="prefix"></i>
            </el-input>
          </el-form-item>
        </div>
        <!-- 收货人 -->
        <div class="form_item">
          <div class="lable_button">
            <span>收货人</span>
          </div>
          <el-form-item>
            <el-input v-model="ruleForm.consignee.user_name" placeholder="收货人姓名">
              <i class="el-icon-user el-input__icon" slot="prefix"></i>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model="ruleForm.consignee.address" placeholder="收货人地址">
              <i class="el-icon-location el-input__icon" slot="prefix"></i>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model="ruleForm.consignee.telephone" placeholder="联系电话">
              <i class="el-icon-phone el-input__icon" slot="prefix"></i>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model="ruleForm.consignee.fax" placeholder="传真">
              <i class="el-icon-chat-dot-square el-input__icon" slot="prefix"></i>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model="ruleForm.consignee.email" placeholder="邮箱">
              <i class="el-icon-message el-input__icon" slot="prefix"></i>
            </el-input>
          </el-form-item>
        </div>
        <!-- 通知人 -->
        <div class="form_item">
          <div class="lable_button">
            <span>通知人</span>
          </div>
          <el-form-item>
            <el-input v-model="ruleForm.notify_party.user_name" placeholder="通知人姓名">
              <i class="el-icon-user el-input__icon" slot="prefix"></i>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model="ruleForm.notify_party.address" placeholder="通知人地址">
              <i class="el-icon-location el-input__icon" slot="prefix"></i>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model="ruleForm.notify_party.telephone" placeholder="联系电话">
              <i class="el-icon-phone el-input__icon" slot="prefix"></i>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model="ruleForm.notify_party.fax" placeholder="传真">
              <i class="el-icon-chat-dot-square el-input__icon" slot="prefix"></i>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model="ruleForm.notify_party.email" placeholder="邮箱">
              <i class="el-icon-message el-input__icon" slot="prefix"></i>
            </el-input>
          </el-form-item>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    address_user: {
      type: Number | String,
      default: "",
    },
    info: {
      type: Object | String,
      default: "",
    },
    from: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      dialogTitle: "",
      dialogTableVisible: false,
      tableData: [],
      ruleForm: {
        shipper: {
          user_name: "",
          fax: "",
          address: "",
          telephone: "",
          email: "",
        },
        consignee: {
          user_name: "",
          fax: "",
          address: "",
          telephone: "",
          email: "",
        },
        notify_party: {
          user_name: "",
          fax: "",
          address: "",
          telephone: "",
          email: "",
        },
      },
      rules: {},
    };
  },
  mounted() {
  },
  methods: {
    fillData(info){
      if (info && info.shipper) {
        this.ruleForm.shipper = info.shipper
        this.ruleForm.consignee = info.consignee
        this.ruleForm.notify_party = info.notify_party
      }
    },
    openDialog(type) {
      this.dialogTableVisible = true;
    },
    submitForm() {
      return new Promise((resolve, reject) => {
        this.$refs["ruleForm"].validate((valid) => {
          if (valid) {
            let data = this.ruleForm;
            let parmas = {};
            parmas.shipper = JSON.stringify(data.shipper);
            parmas.consignee = JSON.stringify(data.consignee);
            parmas.notify_party = JSON.stringify(data.notify_party);
            resolve(parmas);
          } else {
            resolve(false);
          }
        });
      });
    },
  },
};
</script>

<style scoped lang="less">
.itemBox {
  margin-top: 10px;
  border-radius: 4px;
  padding: 20px 10px;
  border: 1px solid #EBEEF5;
  background-color: #FFF;
  color: #303133;
  transition: .3s;

  .boxTitle {
    border-left: 3px solid #409eff;
    padding-left: 10px;
    font-size: 16px;
    margin-bottom: 20px;
  }
}

.demo-ruleForm .form_div_box {
  display: flex !important;
}

.form_item {
  width: 33%;
}

::v-deep .el-form-item,
::v-deep .el-form-item {
  margin-bottom: 5px;
}

.lable_button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 80px;
  padding-left: 15px;
  padding-bottom: 15px;
}

.search {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
</style>