import { render, staticRenderFns } from "./tabs.vue?vue&type=template&id=4a45e978&scoped=true"
import script from "./tabs.vue?vue&type=script&lang=js"
export * from "./tabs.vue?vue&type=script&lang=js"
import style0 from "./tabs.vue?vue&type=style&index=0&id=4a45e978&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../../../../../../opt/deploy_node_modules/ailaworld/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a45e978",
  null
  
)

export default component.exports