<template>
  <div v-loading="loading">
    <div class="header" v-if="tableData.length">
      <el-button class="ml20" type="primary" :loading="btnLoading" size="small" v-if="baseInfo.release_status == 3 && baseInfo.is_apply != 1" @click="applyPull">申请放单</el-button>
      <el-button class="ml20" type="info" :loading="btnLoading" :disabled="true" size="small" v-else>申请放单</el-button>
      <span class="ml20">开票信息可在 交易管理 - 我的账单 中查看</span>
    </div>
    <div class="" v-for="item in tableData" :key="item.id">
      <div class="contentHead">
        <span>
          结算金额： <span class="red">{{item.include_amount | textFormat}} {{item.bill_currency_code | textFormat}}</span>
        </span>
        <span class="ml20">
          账单号： {{item.bill_no | textFormat}}
        </span>
        <span class="ml20">
          账单类型： {{item.is_income==1?'客户付款':'平台退款'  }}
        </span>
        <span class="ml20">
          对账状态： 
          <template v-if="item.is_confirm==1">
            已确认
          </template>
          <span v-else-if="item.is_confirm == 2" class="red">部分确认</span>
          <span v-else class="red">未确认</span>
        </span>
        <span class="ml20">
          开票抬头： <span class="blue">{{item.title | textFormat }}</span>
        </span>
        <span class="ml20 fr">
          <el-button type="primary" size="small" disabled v-if="item.receipt_status == 1">部分开票</el-button>
          <el-button type="success" size="small" disabled v-else-if="item.receipt_status == 2">全部开票</el-button>
          <el-button type="danger" size="small" disabled v-else-if="item.receipt_status == 0">待开票</el-button>
        </span>
        <el-button class="fr" type="primary" v-if="(item.is_confirm!=1 && item.is_confirm!=2) && item.is_income == 1" @click="twoConfrim(item)" size="small">全部确认</el-button>
      </div>
      <div class="contentBottom">
        <el-table
          :data="item.fee_list"
          style="width: 100%"
          :border="true"
          size="small"
          :header-cell-style="{background:'#f5f7fa'}"
          :tableLoading="loading"
        >
          <el-table-column label="币种" :show-overflow-tooltip="true"     >
            <template slot-scope="scope">{{scope.row.currency_code | textFormat }}</template>
          </el-table-column>
          <el-table-column label="费用名称" :show-overflow-tooltip="true" >
            <template slot-scope="scope">{{scope.row.fee_name | textFormat }}</template>
          </el-table-column>
          <!-- <el-table-column label="费用类型" :show-overflow-tooltip="true" >
            <template slot-scope="scope">{{scope.row.fba_fee_type==1?'客户付款':'平台退款'  }}</template>
          </el-table-column> -->
          <el-table-column :show-overflow-tooltip="true" label="单位">
            <template slot-scope="scope">{{scope.row.measure_unit_code | unitArrFormat}}</template>
          </el-table-column>
          <el-table-column label="数量" :show-overflow-tooltip="true" >
            <template slot-scope="scope">{{scope.row.quantity | textFormat }}</template>
          </el-table-column>
          <el-table-column label="单价" :show-overflow-tooltip="true" >
            <template slot-scope="scope">{{scope.row.unit_rate | textFormat }}</template>
          </el-table-column>
          <el-table-column label="含税价" :show-overflow-tooltip="true"     >
            <template slot-scope="scope">{{scope.row.include_tax | textFormat }}</template>
          </el-table-column>
          <el-table-column label="不含税价" :show-overflow-tooltip="true"     >
            <template slot-scope="scope">{{scope.row.except_tax | textFormat }}</template>
          </el-table-column>
          <el-table-column label="税率" :show-overflow-tooltip="true"     >
            <template slot-scope="scope">{{scope.row.val_rate | textFormat }}</template>
          </el-table-column>
          <el-table-column label="税额" :show-overflow-tooltip="true"     >
            <template slot-scope="scope">{{scope.row.val_amount | textFormat }}</template>
          </el-table-column>
          <el-table-column label="备注" :show-overflow-tooltip="true"     >
            <template slot-scope="scope">{{scope.row.remarks | textFormat }}</template>
          </el-table-column>
          <el-table-column label="操作">
            <div slot-scope="scope">
              <span v-if="scope.row.is_confirm == 1">已确认</span>
              <template v-else-if="item.is_income == 1">
                <el-button type="text" @click="twoConfrim(scope.row,true)">确认</el-button>
                <el-button type="text" @click="editRemarks(scope.row)">编辑</el-button>
              </template>
            </div>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="nomsg" v-if="tableData.length < 1" style="padding:20px">还未生成业务账单，请耐心等候</div>
    <el-dialog title="修改备注" :visible.sync="dialogFormVisible">
      <el-input
        type="textarea"
        :rows="4"
        placeholder="请输入内容"
        maxlength="300"
        show-word-limit
        v-model="remarksCont">
      </el-input>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogFormVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="remarksModify" v-loading="btnLoading">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { secondFormat } from "~/basePlugins/filters";
import { unitArr } from "@/baseUtils/enumValue";
export default {
  name: "myBillDetail",
  data() {
    return {
      readonlyBol:true,
      tableData:[],
      remarksCont: '',
      dialogFormVisible: false,
      tableHeight: 100,
      loading:false,
      btnLoading:false,
      bankName:"",
      bankNo:"",
      baseInfo:{}
    };
  },
  mounted() {
    
  },
  watch:{
  },
  computed: {},
  filters:{
    unitArrFormat(input, label = 'label') { //单位过滤
      if (!input && input !== 0) {
          return '-'
      }
      let current = unitArr.find(item => {
          return item.value == input
      });
      if (current) {
          return current[`${label}`]
      } else {
          return input
      }
    },
  },
  methods: {
    fillData(baseInfo){
      if(baseInfo){
        this.baseInfo = baseInfo;
      }
      this.getList();
    },
    async getList() {
      this.loading = true;
      let params = {
        order_id: this.$route.query.id,
      };
      let res = await Promise.all([
        this.$store.dispatch("baseConsole/getSeaBillList", params),
      ]);
      if(res[0].success){
        let result = res[0].data;
        this.tableData =  result;
      }
      this.loading = false;
    },
    editRemarks(row){
      this.remarksCont = JSON.parse(JSON.stringify(row.remarks));
      this.feeOperation = row;
      this.dialogFormVisible = true;
    },
    async remarksModify(){
      this.btnLoading = true;
      let result = await this.$store.dispatch('baseConsole/seaFeeRemarksUpdate',{fee_id:this.feeOperation.id, remarks: this.remarksCont});
      if (result && result.success) {
        this.btnLoading = false;
        this.dialogFormVisible = false;
        this.$message.success("操作成功");
        this.getList()
      }
    },
    twoConfrim(data,fee_bol = false){
      this.$confirm('费用确认后不可修改，如有异议，请联系销售人员，如无异议请确认！', "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
            this.confrim(data,fee_bol)
          })
        .catch(() => {});
    },
    applyPull(){
      this.$confirm('确认申请放单？', "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(async() => {
            let result = await this.$store.dispatch('baseConsole/seaApplyPullOrder',{order_id:this.baseInfo.id});
            if (result && result.success) {
              this.loading = false;
              this.$message.success("操作成功");
              this.baseInfo.is_apply = 1;
            }
          })
        .catch(() => {});
    },
    async confrim(row,fee_bol){
      let params = {
        bill_id: row.id,
        user_id: this.USER_INFO.id
      };
      if(fee_bol){
        params.fba_fee_id	= row.id
      }else{
        let fba_fee_ids = row.fee_list.filter(val =>{
          return val.is_confirm != 1 ;
        }).map(item=>{
          return item.id
        });
        params.fba_fee_id	= fba_fee_ids.join(',')
      }
      this.loading = true;
      let result = await this.$store.dispatch(
        "baseConsole/seaBillFeeConfirm",
        params
      );
      if (result && result.success) {
        this.loading = false;
        this.$message.success("操作成功");
        this.getList();
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped lang="less">
.ml20{
  margin-left: 20px;
}
.contentHead{
  padding: 20px 15px 10px;
  font-weight: 600;
  color: #606266;
  
  .red{
    color: #f35555;
  }
  .blue{
    color: #027FFF
  }
}
.contentBox, .contentBottom{
  background: #fff;
  padding: 15px;
  display: flex;
  justify-content: center;
  .inline{
    width: calc(90% - 500px);
    .el-form-item{
      width: 48%;
      /deep/.el-form-item__content{
        width: calc(80% - 150px) ;
      }
      /deep/.el-form-item__label{
        font-weight: 600;
      }
    }
    .w100{
      /deep/.el-form-item__content{
        width: calc(100% - 140px) ;
      }
    }
  }
}
.contentBottom{
  width: 100%;
  margin-bottom: 20px;
}
.nomsg{
  height: 150px;
  line-height: 150px;
  text-align: center;
}
</style>
