<template>
    <div class="body">
        <div class="top flex">
            <img :src="logo" alt="">
        </div>

        <div class="item txt">
            <span class="lable" style="width:25px">TO:</span>
            <span class="value">{{ PDFDataMsg.entrust_company_zh }} </span>
        </div>

        <div class="flex between">
            <div class="item txt flex" style="width:60%">
                <span class="lable">贵公司委托我公司出运到</span>
                <span class="value" style="word-wrap: break-word;width: 300px;padding-right: 20px;">
                    {{ PDFDataMsg.unload_port_name_en }}
                </span>
            </div> 
            <div class="item txt" style="width:40%">
                <span class="lable" style="width:auto">进仓编号为：</span>
                <span class="value">{{ PDFDataMsg.warehouse_no }}</span>
            </div> 
        </div>
    
        <div class="flex between width33 pd">
            <div class="text_over">离港日期：{{ PDFDataMsg.etd | secondFormat("LLL") }}</div>
            <div  class="text_over">危险品等级：{{ PDFDataMsg.dangerous_class }}</div>
            <div class="text_over">联合国编码：{{ PDFDataMsg.un_no }}</div>
        </div>

        <div class="flex between width33 pd margin_bottom_30">
            <div>件数：{{ PDFDataMsg.quantity }}</div>
            <div>重量：{{ PDFDataMsg.gross_weight }}</div>
            <div>体积：{{ PDFDataMsg.volume }}</div>
        </div>

   

        <div class="box flex width50 margin_bottom_20">
            <div class="left">
                <div class="red" style="color:red">货物请送以下地址：</div>
                <div>{{ PDFDataMsg.address }}</div>
                <div class="margin_bottom_20">进仓联系人{{ PDFDataMsg.contacts }}{{ PDFDataMsg.telephone }}</div>
                <div>仓库名称：{{ PDFDataMsg.warehouse_name }}</div>
                <div>联系人：{{ PDFDataMsg.contacts }}</div>
                <div>电话：{{ PDFDataMsg.telephone }}</div>
            </div>
            <div class="right">
                <div class="red">资料请送以下地址：</div>
                <div>上海市杨浦区荆州路168号安联大厦5楼509室</div>
                <div>公司名称：上海玉漭物流科技有限公司</div>
                <div>联系人：{{ PDFDataMsg.operator_name_zh }}</div>
                <div class="margin_bottom_30">电话：{{ PDFDataMsg.operator_mobile }}</div>
                <div>单证截止时间：{{ PDFDataMsg.dod  | secondFormat("LLL") }}</div>
            </div>
        </div>




        <div class="margin_bottom_60">
            <div>如有任何疑问，请与我司操作{{ PDFDataMsg.operator_name_zh }}联系，方法如下：</div>
            <div>操作：{{ PDFDataMsg.operator_name_zh }}</div>
            <div>手机：{{ PDFDataMsg.operator_mobile }}</div>
            <div>邮箱：{{ PDFDataMsg.operator_email }}</div>
        </div>
<div style="border: 1px solid #000;padding:5px">
    <pre class="txt">
备注：
1、请开船前3天提供正确的唛头，如果N/M请在货物外标明进仓编号或做好其他标记并通知我司操作；
2、若退关须在船开前10个工作日通知我司，如在规定时间后退关，必须承担全额海运费作为亏舱费；

危险品备注：


1、进仓货物请注明进仓编号和出示中文品名
2、货物上要求贴的相关危标等，请严格按照IMDG包装准则要求！！！
3、货物请贴上相关危险货物标签,否则在中转港/目的港所产生的一切责任、风险及费用自负(请严格按
照IMDG包装准则要求。CLASS / UNNO. / PROPER SHIPPING NAME）
    </pre>
</div>
    </div>
</template>
<script>

export default {
    props:{
        PDFDataMsg:{
            type:Object,
            default:function(){
                return {}
            }
        }
    },
    data(){
        return{
            logo: require("~/baseAssets/ymp_pdf_logo.png"),
        }
    }
}
</script>
<style scoped>
    .text_over{
        word-wrap: break-word;
        padding-right: 20px;
    }
    pre,.txt,.item, span .txt,div,.left>div{
            color: rgb(0, 0, 0) ;
            font-size: 12px !important;
            line-height: 18px;
        }
        img{
            height: 160px;
            margin: auto;
        }
        .body{
            width: 790px;
            margin: auto;
            padding: 20px;
        }
        .flex{
            display: flex;
        }
        .around{
            justify-content: space-around;
        }
        .between{
            justify-content: space-between;
        }
        .center{
            align-items: center;
        }
        .item{
            padding: 10px 0;
        }
        .pd{
            padding: 10px 0;
        }
        .lable{
            display: inline-block;
            width: 170px;
        }
        .width33>div{
            width: 33%;
        }
        .width50>div{
            width: 50%;
        }
        .box{
            border: 1px solid #000;
        }
        .left{
            padding: 5px;
            border-right:1px solid #000;
        }
        .right{
            padding: 5px;
        }
        .margin_bottom_20{
            margin-bottom: 20px;
        }
        .margin_bottom_30{
            margin-bottom: 30px;
        }
        .margin_bottom_60{
            margin-bottom: 60px;
        }
        .red{
            color: red !important;
            margin-bottom: 40px;
        }

</style>